import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Content } from "./components/content";

function App() {
  return (
    <ChakraProvider>
      <Content />
    </ChakraProvider>
  );
}

export default App;

import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const LINKEDIN = "https://www.linkedin.com/in/tiago-fonseca/ ";

const UserCard = ({ name, avatar_url, html_url, linkedin_url }) => (
  <Card maxW="md">
    <CardHeader>
      <Flex spacing="4">
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <Avatar name={name} src={avatar_url} />

          <Box>
            <Heading size="sm">{name}</Heading>
            <Text>FullStack Engineer</Text>
          </Box>
        </Flex>
      </Flex>
    </CardHeader>
    <CardBody>
      <Text>
        I'm a software engineer with 8+ years of experience specializing in web
        development, hybrid apps, and front-end optimization.
      </Text>
    </CardBody>
    <CardFooter
      justify="space-between"
      flexWrap="wrap"
      textAlign="center"
      sx={{
        "& > a": {
          minW: "136px",
        },
      }}
    >
      <Link flex="1" href={html_url} isExternal>
        GitHub <ExternalLinkIcon mx="2px" />
      </Link>
      <Link flex="1" href={linkedin_url} isExternal>
        LinkedIn <ExternalLinkIcon mx="2px" />
      </Link>
    </CardFooter>
  </Card>
);

export function Content() {
  return (
    <Box h="100vh" backgroundColor="gray.100">
      <VStack pt={32}>
        <Container centerContent>
          <UserCard
            avatar_url="https://avatars.githubusercontent.com/u/2777865?v=4"
            html_url="https://github.com/tiagoapolo"
            linkedin_url={LINKEDIN}
            name="Tiago Paiva"
          />
        </Container>
      </VStack>
    </Box>
  );
}
